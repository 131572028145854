  <div class="row">
    <p class="disclaimer">Your privacy is protected 100%. None of your information is tracked.<br>
      There are no cookies. None of your data is stored or captured.</p>
  </div>

<style>
  .disclaimer {
    color: #dedede;
    max-width: 50em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    margin: 1em auto;
  }
</style>
